<template>
    <div class="stream-suggestion">
        <span class="header-title fira">{{ $fn.tr('oTHER PROJECTS LIVE CAMERAS') }}</span>
        <div class="suggestion-list">
            <template v-for="item in activeProjects">
                <div v-if="item.show"
                     @click="changeUrl(item.slug)"
                     class="suggestion-item">
                    <div class="image-inner">

                        <picture class="picture">
                            <source
                                media="(max-width:767px)"
                                :srcset="item.mobile"
                            />
                            <source
                                media="(max-width:1023px)"
                                :srcset="item.tablet"
                            />
                            <img
                                class="image"
                                :src="item.desktop"
                                @mouseenter="store.getComponentCursor($event, 'play')" data-scale-type="1.2"
                                @mouseleave="store.getComponentCursor($event, null)"
                            />
                        </picture>
                    </div>
                    <div class="content">
                        <h2 class="title luxury">{{ item.title }}</h2>
                        <div class="explore-container">
                            <svg width="38" height="10" class="icon" viewBox="0 0 38 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166
                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415
                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746
                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015
                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961
                              0.65 5.64961H35.0287L31.8652 8.75479Z"
                                      fill="#940128"></path>
                            </svg>
                            <span class="title fira">{{ $fn.tr('live cam') }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useMenuStore} from "@/store/pinia/menu";

const store = useMenuStore();
const props = defineProps({
    projects: {
        type: Object,
    },
});
const route = useRoute()
const router = useRouter()

let activeProjectSlug = route.params?.path2

let activeProjects = computed(() => {
    return props.projects?.filter(item => item.slug !== activeProjectSlug && item?.live_cameras?.en?.[0]?.tipHover)?.slice(0, 2)?.map(item => {
        return {
            ...item,
            desktop: item.camera_image?.[0]?.devices?.desktop,
            mobile: item.camera_image?.[0]?.devices?.mobile,
            tablet: item.camera_image?.[0]?.devices?.tablet,
            show: item?.live_cameras?.['en']?.[0]?.tipHover
        }
    })
})
const changeUrl = (url) => {
    router.push({
        path: url,
    })
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.stream-suggestion {
    color: $primaryNavy;
    margin-top: 89px;
    @media only screen and (max-width: 767px) {
        margin-top: 55px;
    }

    .header-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
    }

    .suggestion-list {
        margin-top: 21px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }
    }


    .suggestion-item {
        width: calc(50% - 10px);
        position: relative;
        cursor: pointer;
        @media only screen and (max-width: 767px) {
            width: 100%;
            &:last-child {
                margin-top: 21px;
            }
        }

        .image-inner {
            height: 377px;
            position: relative;
            @media only screen and (max-width: 1650px) and (min-width: 768px) {
                height: 300px;
            }
            @media only screen and (max-width: 767px) {
                height: 250px;

            }

            .play-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            margin-top: 21px;
            margin-left: 25px;

            .title {
                font-weight: 400;
                font-size: 55px;
                text-transform: uppercase;
                @media only screen and (max-width: 1650px) {
                    font-size: 34px;
                }
            }
        }

        .explore-container {
            display: flex;
            align-items: center;
            margin-top: 15px;

            .icon {
                margin-right: 15px;
                width: 25px;
            }

            .title {
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }
        }

    }
}
</style>

<style lang="scss">
body {
    &.is-dark {
        .stream-suggestion {
            color: white;
        }
    }
}

</style>
